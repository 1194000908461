<template>
    <div class="banner-manage">
        <div class="table-left">
            <ContentTable :tableData='tableData' tableType='table' @page-change='pageChange'>
               <template slot='imgPre' slot-scope="data" class="table-operate">
                    <div
                         style="width:100%;height:100%;cursor:pointer;line-height:1;display:flex;align-items:center;">
                        <el-image :src="serverUrl+ data.data.row.bannerUrl"
                                  style="width:100%;height:80%;cursor:pointer;" fit='contain'
                                  :preview-src-list="[serverUrl+ data.data.row.bannerUrl]">>
                            <div slot="error" class="image-slot"
                                 style="width:100%;height:100%;display:flex;align-items:center;font-size:15px;color:#3c546a;justify-content: center;">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                </template>
                <template slot="operate" slot-scope="data">
                   <span @click.stop="goDelete(data.data.row)" class="table-btn">删除</span>
                </template>
            </ContentTable>
        </div>
        <div class="auth-tree">
            <div class="operate">图片上传</div>
            <div class="form">
                <div class="item" v-for=" (from_item,item) in formData" :key="item" >
                    <comFrom nameWidth="150px" v-model="from_item.value" @expandData="expandData"
                        :name="from_item.id" data-vv-delay="100" :errorsInfo="errors.first(from_item.id)"
                        :data='from_item' v-validate="from_item.check_type"
                    > 
                </comFrom>
                </div>
                <div class="footer">
                    <font-button class="button" @click="saveData">保存</font-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {tableData_banner} from "../common/data"
import basePage from "../common/basePage.vue"
import {ajax_get_bannerList, ajax_post_bannerAdd, ajax_get_bannerDelete, } from "../../../api/index"
export default {
    name:"bannerManage",
    mixins: [basePage],
    data(){
        return {
            serverUrl: "https://wrjc.wanrongly.com/",
            formData:[],
            formDataUrl:[
                {
                  id: "url",
                  name: "轮播图",
                  value: '',
                  type: "img",
                  check_type: 'required',
                  placeholder: "增加信誉度",
                  unit: '',
                  slot: "",
                  options: [],
                  show:true,  
                  expand:{
                      limit:1
                  }
                },
            ],
            bannerName:""
        }
    },
    created(){
        this.pageInit();
    },
    methods:{
        pageInit(){
            this.ajaxListMethods = ajax_get_bannerList;
            this.tableData = this.$deepClone(tableData_banner)
            this.formData = this.$deepClone(this.formDataUrl)
            this.ajax_list_get(true)
        },
        handerTableData(list){
          let resList = [];
          list.forEach((element,index)=>{
            element.index = (this.pageData.pageNo - 1) * this.pageData.pageSize + index +1
            resList.push(element)
          })
          return resList;
        },
        goDelete(data){
            this.$Confirm({
              title:'警告',
              info:'请确认是否删除此信息',
            }).then(()=>{
              const params = {
                id: data.id,
              }
              ajax_get_bannerDelete(params).then(res=>{
                if(res.code == 200){
                  this.$message.success("操作成功")
                  this.ajax_list_get()
                }
              }).catch(err=>{
                console.log("ajax_err:", err)
              })
            })
        },
        expandData(data){
            this.bannerName = data.split(".")[0];
        },
        saveData(){
            if(!this.formData[0].value){
                this.$message.warning("请上传图片")
                return 
            }
            const params = {
                bannerName: this.bannerName,
                bannerUrl: this.formData[0].value
            }
            ajax_post_bannerAdd(params).then(res=>{
                if(res.code == 200){
                    this.formData = this.$deepClone(this.formDataUrl)
                    this.$message.success("操作成功");
                    this.ajax_list_get()
                }
            }).catch(err=>{
                console.log("ajax_err:", err)
            })
        }
    }
}
</script>
<style lang="less" scoped>
.banner-manage{
    width: 100%;
    height: 100%;
    display: flex;
    .table-left{
        width: 60%;
        height: 100%;
        padding-right: 10px;
        box-sizing: border-box;
        .btn-box{
            text-align: right;
            margin-bottom: 10px;
        }
    }
    .auth-tree{
        width: 40%;
        height: 100%;
        border-left: 1px solid rgb(228, 231, 237);
        padding-left: 10px;
        box-sizing: border-box;
        .operate{
            display: flex;
            line-height: 30px;
            margin-bottom: 10px;
            font-weight: 400;
        }
        .footer{
            width: 75%;
            margin-top: 30px;
        }
    }
}
</style>